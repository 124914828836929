import request from '@/utils/request'

const commodity_qidApi = {
  commodity_qid_list: '/commodity/qid/',
  commodity_qid_create: '/commodity/qid/',
  commodity_qid_update: '/commodity/qid/',
  commodity_qid_delete: '/commodity/qid/',
  qid_record_list: '/qid/'
}

/**
 * 列表
 */
export function qid_record_list (parameter, id) {
  return request({
    url: commodity_qidApi.qid_record_list + id + '/record',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 列表
 */
export function commodity_qid_list (parameter) {
  return request({
    url: commodity_qidApi.commodity_qid_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function commodity_qid_create (parameter) {
  return request({
    url: commodity_qidApi.commodity_qid_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
