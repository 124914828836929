<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="exhibition_commodity_id" v-show="false">
              <a-input v-decorator="['exhibition_commodity_id', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="数量">
              <a-input v-decorator="['count', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'

// 表单字段
const fields = ['exhibition_commodity_id', 'count']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      dataSource_exhibition_goods: [],
      color: '#3f3f3f',
      form: this.$form.createForm(this)
    }
  },
  created () {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.model && this.form.setFieldsValue(pick(this.model, fields))
  },
  mounted () {
    console.log(this.form.getFieldValue('exhibition_commodity_id'))
  },
  methods: {}
}
</script>
