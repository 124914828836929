<template>
  <div>
    <s-table
      ref="table"
      size="middle"
      rowKey="event_time"
      :pagination="false"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/QIDCreateForm'
import { qid_record_list } from '@/api/qid'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      previewvisible: false,
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          align: 'center',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '编码',
          dataIndex: 'code_number'
        },
        {
          title: '事件',
          align: 'center',
          dataIndex: 'event'
        },
        {
          title: '发生时间',
          align: 'center',
          dataIndex: 'event_time'
        },
        {
          title: '类型',
          align: 'center',
          dataIndex: 'type',
          customRender: (text) => this.$Dictionaries.InspurBatchInsertType[text]
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return qid_record_list(Object.assign(parameter, this.queryParam), this.$route.params.id)
          .then(({ data }) => {
            console.log(data, '我是数据列表@')
            return { entries: data }
          })
      }
    }
  },
  created () {},
  methods: {}
}
</script>
